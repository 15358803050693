interface ApiGatewayDomains {
    PermissionsManagerGamma: string;
    PermissionsManagerProd: string;
}

export const apiGatewayDomains: ApiGatewayDomains = {
    PermissionsManagerGamma: 'https://permissions-gamma-backend.learning.ctps.a2z.com',
    PermissionsManagerProd: 'https://permissions-backend.learning.ctps.a2z.com'
};

export function getPermissionsManagerApiGatewayDomain(): string {
    if(window.location.origin.includes('prod')){
        return apiGatewayDomains.PermissionsManagerProd;
    }
    else{
        return apiGatewayDomains.PermissionsManagerGamma;
    }
}


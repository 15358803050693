import { useState, useEffect } from 'react';
import axios from 'axios'
import { unmarshall } from "@aws-sdk/util-dynamodb";
import Cohorts from '../types/Cohort'
import { PERMISSIONS_TOOL_URL } from '../utils/constants'

const useCohortDetailsById = (cohortId: string): [Cohorts[], boolean, null] => {
    const [data, setData] = useState<Cohorts[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
      // Set loading and error states
      setLoading(true);
      setError(null);

      axios.get(`${PERMISSIONS_TOOL_URL}/cohorts/byid/${cohortId}`, {withCredentials: true})
      .then (response => {
          let unmarshalledResponse =  response.data.Items.map(element => {
            return unmarshall(element)
          })
          setLoading(false)
          setData(unmarshalledResponse)
      })
      .catch(err => {
          setError(err)
          setLoading(false)
      })
    }, [cohortId])
    return [data, loading, error];
  }

export default useCohortDetailsById;
import axios from 'axios';
import { useState, useEffect } from 'react';
import { PERMISSIONS_TOOL_URL } from '../utils/constants'

const useRole = (login) => {
    const [role, setRole] = useState<string>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get(`${PERMISSIONS_TOOL_URL}/getrole/${login}`, {withCredentials: true})
        .then(response => {
            //Any role is treated as trainer for now
            setRole("trainer")
            setLoading(false)
        })
        .catch(err => {
            //Anyone not present in the Permissions tool is considered a learner
            setRole("learner")
            setLoading(false)
        })
    })

    return { role, loading }
}

export default useRole